import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const AboutStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 7rem 9rem 9rem;
  flex-wrap: wrap;
  ${breakpoint("mobile")`
  padding: 3rem 3rem 9rem;

  `}
  ${breakpoint("tablet")`
  padding: 5rem 7rem 9rem;

  `}
  ${breakpoint("desktop")`
  padding: 7rem 9rem 9rem;

  `}
`
const AboutText = styled.div`
  font-size: 1.8rem;
  font-family: warnock-pro-display, serif;
  ${breakpoint("mobile")`
  font-size: 1.3rem;

  `}
  ${breakpoint("tablet")`
  font-size: 1.8rem;

  `}
  ${breakpoint("desktop")`
  font-size: 1.8rem;

  `}
`

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      about: wordpressPage(slug: { eq: "about" }) {
        title
        content
      }
    }
  `)

  return (
    <>
      <AboutStyled>
        <AboutText
          dangerouslySetInnerHTML={{ __html: data.about.content }}
        ></AboutText>
      </AboutStyled>
    </>
  )
}

export default About
